import { useCookies } from "react-cookie";

const App = () => {

    const [cookies, setCookie] = useCookies(['auth']);

    setCookie('auth', {
        "data": {
            "authToken": "eyJhbGciXXxxxxxMTYyMDgyNDgyOSwiZGV2aWNlIjoiIiwXXXXXXXXXXXXXXXXXXhvc3Q6MzAwMCIsInJlbW90ZUlwIjoiMTkyLjE2OC42NC4xIn0.cNuyPtfnieeU59aO5-iZAxAe9Rfntr8doQex1rCz4ko",
            "authTokenExpire": 1620824829000,
            "refreshToken": "eyJhbGciOiJIUxxxxXXXXXXXXXAsImlhdCI6MTYyMTM0MzIXXXXXXXXXXXXXXX3VyY2UiOiJsb2NhbGhvc3Q6MzAwMCIsInJlbW90ZUlwIjoiMTkyLjE2OC42NC4xIn0.5aJvspkB5tFCtAs6uLZJu_XhpzF7kDDYAAp2hHFF8V8",
            "refreshTokenExpire": 1621343229000
        }
    });

    console.log('Cookies', cookies.auth.data);

    return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        background: "#192c50",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 style={{ color: "white" }}>Storybook</h1>
    </div>
  );
};

export default App;
